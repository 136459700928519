<template>
    <base-dialog
        v-model="opened"
        :title="title"
        confirm-text="Confirm"
        :confirm-action="deleteAction"
        confirm-button-color="danger"
        v-on="$listeners"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs"></slot>
        </template>

        <template v-slot:content>
            <div class="ma-4">{{ deleteText }}</div>
        </template>
    </base-dialog>
</template>

<script>
import BaseDialog from '@/components/modal/BaseDialog'

export default {
    name: 'DeleteDialog',
    components: { BaseDialog },
    props: {
        title: {
            type: String,
            default: 'Delete',
        },
        deleteAction: {
            type: Function,
            default: () => {},
        },
        deleteText: {
            type: String,
            default: 'Are you sure you want to delete this?',
        },
    },
    data: () => ({
        opened: false,
    }),
}
</script>